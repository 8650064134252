<template>
  <b-card
    id="reservation-list"
    no-body
    class="mb-2 box-shadow-0 border p-1"
  >
    <b-overlay
      :show="loading"
      spinner-variant="warning"
      rounded="sm"
      no-fade
      variant="transparent"
      :opacity="0.5"
    >
      <!-- Filters -->
      <ReservationFilter
        :search-text-filter="searchTextFilter"
        :booking-code-filter="bookingCodeFilter"
        :created-by-filter.sync="createdByFilter"
        :agency-code-filter.sync="agencyCodeFilter"
        :brand-filter.sync="brandFilter"
        :source-filter.sync="sourceFilter"
        :status-filter-by-user.sync="statusFilterByUser"
        :start-date-filter.sync="startDateFilter"
        :start-date-flight-filter.sync="startDateFlightFilter"
        :end-date-flight-filter.sync="endDateFlightFilter"
        :end-date-filter.sync="endDateFilter"
        :status-options="optionsStatus"
        :brand-options="optionsAirLine"
        :source-options="optionsSource"
        :empty-filter="isEmptyFilter"
        :is-active-filter.sync="isActiveFilter"
        :is-export="false"
        @fetch-data="refetchData"
        @updateSearchTextFilter="(value) => updateSearchText(value)"
        @updateBookingCodeFilter="(value) => updateBookingCode(value)"
        @reset="clearFilter"
      />

      <!-- Table Container Card -->
      <b-table
        ref="refReservationListTable"
        style="max-height: 70vh"
        :sticky-header="true"
        :items="fetchPastDayBookings"
        :striped="true"
        class="position-relative p-0 rounded"
        :hover="true"
        :borderless="true"
        :fields="tableColumns"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        responsive
        no-border-collapse
        :empty-text="$t('noMatchingResult')"
        small
      >
        <!-- ANCHOR - Column Name -->
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $t(`reservation.${data.label}`) }}
          </span>
        </template>

        <!-- ANCHOR - Row Details -->
        <template #row-details="row">
          <!-- ANCHOR - roundTripBooking -->
          <b-card
            v-if="row.item.roundtripBooking"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Chuyến khứ hồi:
                </span>
              </div>
            </template>
            <b-table
              striped
              thead-class="d-none"
              table-class=""
              class="position-relative p-0 rounded mb-0"
              borderless
              responsive
              no-border-collapse
              :items="[row.item.roundtripBooking]"
              :fields="tableColumns"
              :empty-text="$t('noMatchingResult')"
              small
            >
              <template #cell(source)="data">
                <div class="text-nowrap d-flex-center">
                  <span
                    class="h5 mb-0 py-25 px-50"
                    :style="resolveBorderColorByAirline(data.item.source, 900)"
                    :class="data.item.extra && data.item.extra.length && data.item.extra[0].contentSource ? `logo-${data.item.extra[0].contentSource}` : ''"
                  >
                    {{ data.item.source }}
                  </span>
                </div>
              </template>

              <template #cell(bookingCode)="data">
                <div class="text-center ml-2">
                  <b-link
                    :disabled="!canAccess('booking.detailBooking')"
                    :to="{ name: 'apps-past-day-booking-modify', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.bookingCode }}
                  </b-link>
                  <b-button
                    v-if="data.item.roundtripBooking"
                    size="sm"
                    variant="info"
                    class="font-medium-2 py-25 px-50 font-weight-bold"
                    @click="data.toggleDetails"
                  >
                    <!-- {{ data.detailsShowing ? 'Hide' : 'Show' }} -->
                    {{ data.item.roundtripBooking.bookingCode }}
                  </b-button>
                </div>
              </template>

              <template #cell(status)="data">
                <div class=" ml-3">
                  <b-badge
                    :variant="
                      `light-${resolveBookingStatusVariant(
                        data.item.status|| data.item.bookingStatus,
                      )}`
                    "
                    :class="`badge-glow round text-uppercase`"
                  >
                    {{ $t(`reservation.${data.item.status}`) }}
                  </b-badge>
                  <div>
                    <small v-if="data.item.timeHold && !data.item.status.toUpperCase().includes('PAID')">
                      ({{ convertISODateTime(data.item.timeHold).time }}
                      {{ convertISODateTime(data.item.timeHold).date }})
                    </small>
                  </div>
                </div>
              </template>

              <template #cell(passenger)="data">
                <div
                  v-for="(pax, index) of data.item.paxLists"
                  :key="index"
                  class="ml-2"
                >
                  <small class="text-body-3 text-nowrap">
                    {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
                  </small>
                </div>
              </template>

              <template #cell(booker)="data">
                <div
                  :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
                  class="w-100 h-100"
                >
                  <small class="text-info font-weight-bold text-body-3 text-nowrap">
                    {{ data.item.createdBy.username }}
                  </small>
                  <b-tooltip
                    :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="info"
                  >
                    <div>
                      {{ data.item.createdBy.lastName }}
                      {{ data.item.createdBy.firstName }}
                      ({{ data.item.createdBy.type }})
                    </div>
                    <div>
                      {{ data.item.createdBy.employeeCode }}
                    </div>
                  </b-tooltip>
                </div>
              </template>

              <template #cell(flight)="data">
                <div v-if="data.item.bookingRequest">
                  <div
                    v-for="(trip, index) of resolveBookingRequest(data.item.bookingRequest.itineraries)"
                    :key="index"
                    class="text-nowrap text-body-3"
                  >
                    <div
                      v-for="(segment,indexSegment) in trip.segments"
                      :key="indexSegment"
                      class="d-flex flex-nowrap my-50"
                    >
                      <IAmLogoAirline
                        v-if="segment.airline"
                        :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                        size="xs"
                        rounded
                        custom-class="mr-50"
                      />
                      <span>
                        {{ segment.devSegmentIndex }}
                        {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}
                        {{ segment.bookingClass }}
                        {{ convertISODateTime(segment.departureTime).dayMonth }}
                        {{ `${segment.departure}${segment.arrival}` }}
                        {{ convertISODateTime(segment.departureTime).hourMin }}
                        {{ convertISODateTime(segment.arrivalTime).hourMin }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div
                    v-for="(trip, index) of data.item.itineraries.filter(trip => trip.some(segment => segment.status !== 'HX'))"
                    :key="index"
                    class="text-nowrap text-body-3"
                  >
                    <div
                      v-for="(segment,indexSegment) in trip"
                      :key="indexSegment"
                      class="d-flex flex-nowrap my-50"
                    >
                      <IAmLogoAirline
                        v-if="segment.airline"
                        :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                        size="xs"
                        rounded
                        custom-class="mr-50"
                      />
                      <span>
                        {{ convertShortTrip(segment) }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(createdAt)="data">
                <b-media no-body>
                  <b-media-body>
                    <h6 class="mb-0">
                      {{ convertISODateTime(data.item.dateCreate).time }}
                    </h6>
                    <h6 class="mb-0">
                      {{ convertISODateTime(data.item.dateCreate).date }}
                    </h6>
                  </b-media-body>
                </b-media>
              </template>

              <template #cell(note)="data">
                <div
                  class="two-line-ellipsis"
                  style=""
                >
                  {{ data.item.note }}
                </div>
              </template>
            </b-table>
          </b-card>

          <!-- ANCHOR - multiCityBookings + book đoàn -->
          <b-card
            v-if="row.item.multiCityBookings && !isEmpty(row.item.multiCityBookings)"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Danh sách vé:
                </span>
              </div>
            </template>

            <b-table
              striped
              thead-class="d-none"
              table-class=""
              class="p-0 rounded mb-0"
              borderless
              no-border-collapse
              :items="row.item.multiCityBookings"
              :fields="['bookingCode']"
              :empty-text="$t('noMatchingResult')"
              small
            >
              <template #cell(bookingCode)="data">
                <div style="margin-left: 150px;">
                  <b-link
                    v-if="data.item.id"
                    :disabled="!canAccess('booking.detailBooking')"
                    :to="{ name: 'apps-past-day-booking-modify', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.bookingCode }}
                  </b-link>

                  <div
                    v-else
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.bookingCode }}
                  </div>
                </div>
              </template>
            </b-table>
          </b-card>

          <!-- ANCHOR - crossReferenceDetails -->
          <b-card
            v-if="!isEmpty(row.item.crossReferenceDetails)"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Số vé liên quan:
                </span>
              </div>
            </template>

            <b-table
              striped
              thead-class="d-none"
              table-class=""
              class="p-0 rounded mb-0"
              borderless
              no-border-collapse
              :items="row.item.crossReferenceDetails"
              :fields="['bookingCode']"
              :empty-text="$t('noMatchingResult')"
              small
            >
              <template #cell(bookingCode)="data">
                <div
                  class=""
                  style="margin-left: 150px;"
                >
                  <b-link
                    v-if="data.item.id"
                    :disabled="!canAccess('booking.detailBooking')"
                    :to="{ name: 'apps-past-day-booking-modify', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.pnrNumber }}
                  </b-link>

                  <div
                    v-else
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.pnrNumber }}
                  </div>
                </div>
              </template>
            </b-table>
          </b-card>

          <!-- ANCHOR - Vé rẻ canh được -->
          <b-card
            v-if="row.item.lowFareTrackingTasks && row.item.lowFareTrackingTasks.some(i => i.result)"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Danh sách mã đặt chỗ từ canh vé thành công:
                </span>
              </div>
            </template>

            <b-table
              striped
              thead-class="d-none"
              table-class=""
              class="p-0 rounded mb-0"
              borderless
              no-border-collapse
              :items="row.item.lowFareTrackingTasks.filter(vr => vr.result).map(task => JSON.parse(task.result).lowFareTrackedBookings.map(ve => ({...ve})))"
              :fields="['bookingCode']"
              :empty-text="$t('noMatchingResult')"
              small
            >
              <template #cell(bookingCode)="{item}">
                <div
                  v-for="(datcho, mIndex) of item"
                  :key="mIndex"
                  class=""
                  style="margin-left: 150px;"
                >
                  <b-link
                    :disabled="!canAccess('booking.detailBooking')"
                    :to="{ name: 'apps-past-day-booking-modify', params: { id: datcho.id } }"
                    class="font-weight-bold d-block text-nowrap text-info py-50"
                  >
                    <div>
                      {{ datcho.pnr }}
                    </div>
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>

          <!-- ANCHOR - Số vé đã thực hiện canh ra vé này -->
          <b-card
            v-if="row.item.lowFareTrackingFrom"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Mã đặt chỗ đã thực hiện canh vé này:
                </span>
              </div>
            </template>
            <b-link
              :disabled="!canAccess('booking.detailBooking')"
              :to="{ name: 'apps-reservations-modify', params: { id: row.item.lowFareTrackingFrom.bookingId } }"
              style="margin-left: 150px;"
              class="font-weight-bold d-block text-nowrap text-info py-50"
            >
              <div>
                {{ row.item.lowFareTrackingFrom.bookingCode }}
              </div>
            </b-link>
          </b-card>
        </template>

        <template #cell(source)="data">
          <div class="text-nowrap d-flex-center">
            <span
              class="h5 mb-0 py-25 px-50 d-flex-center gap-1"
              :style="resolveBorderColorByAirline(data.item.source, 900)"
            >
              {{ data.item.source }}
              <span
                v-if="data.item.extra && data.item.extra.length && data.item.extra[0].contentSource"
                :class="`bagde-${data.item.extra[0].contentSource}`"
              >
                {{ data.item.extra[0].contentSource }}
              </span>
            </span>
          </div>
        </template>

        <template #cell(bookingCode)="data">
          <b-link
            :disabled="!canAccess('booking.detailBooking')"
            :to="{ name: 'apps-past-day-booking-modify', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap text-info pb-25"
          >
            {{ data.item.bookingCode }}
          </b-link>

          <!-- TODO - || (data.item.multiCityBookings && !isEmpty(data.item.multiCityBookings)) -->
          <b-button
            v-if="data.item.roundtripBooking ||
              !isEmpty(data.item.crossReferenceDetails) ||
              (data.item.lowFareTrackingTasks && data.item.lowFareTrackingTasks.some(i => i.result)) ||
              data.item.lowFareTrackingFrom ||
              (data.item.multiCityBookings && !isEmpty(data.item.multiCityBookings))"
            v-b-tooltip.hover.v-warning.window.right
            :title="isMobileView ? '' : (data.item.roundtripBooking ? 'Chuyến khứ hồi' : (!isEmpty(data.item.multiCityBookings) ? 'Vé đoàn': 'Vé liên quan'))"
            size="sm"
            :variant="data.item.roundtripBooking ? 'flat-warning' : 'outline-warning'"
            class="font-medium-1 py-25 d-flex"
            :class="(data.item.roundtripBooking || !isEmpty(data.item.multiCityBookings)) ? 'px-0' : 'px-2'"
            @click="data.toggleDetails"
          >
            <span
              v-if="data.item.roundtripBooking"
              class="mr-25"
            >
              {{ data.item.roundtripBooking.bookingCode }}
            </span>
            <span
              v-else-if="!isEmpty(data.item.multiCityBookings) && data.item.multiCityBookings[0].isBookingGroup"
              class="mr-25 text-nowrap px-25"
            >
              Vé đoàn
            </span>
            <span
              v-else-if="!isEmpty(data.item.multiCityBookings) && !data.item.multiCityBookings[0].isBookingGroup && (data.item.multiCityBookings[0].splitItineraries || data.item.multiCityBookings[0].flightType)"
              class="mr-25 text-nowrap px-25"
            >
              Vé tách chặng
            </span>
            <feather-icon
              v-if="data.detailsShowing"
              icon="ChevronUpIcon"
              size="16"
            />

            <feather-icon
              v-else
              icon="ChevronDownIcon"
              size="16"
            />
          </b-button>
        </template>

        <template #cell(status)="data">
          <b-badge
            :variant="
              `light-${resolveBookingStatusVariant(
                data.item.status|| data.item.bookingStatus,
              )}`
            "
            :class="`badge-glow round text-uppercase`"
          >
            {{ $t(`reservation.${data.item.status}`) }}
          </b-badge>
          <div>
            <small v-if="data.item.timeHold && !data.item.status.toUpperCase().includes('PAID')">
              ({{ convertISODateTime(data.item.timeHold).time }}
              {{ convertISODateTime(data.item.timeHold).date }})
            </small>
          </div>
        </template>

        <template #cell(passenger)="data">
          <div
            v-for="(pax, index) of data.item.paxLists"
            :key="index"
          >
            <small class="text-body-3 text-nowrap">
              {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
            </small>
          </div>
        </template>

        <template #cell(booker)="data">
          <div
            :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
            class="w-100 h-100 d-flex-center flex-column"
          >
            <!-- username -->
            <div class="text-info font-weight-bold text-body-3 text-nowrap">
              {{ data.item.createdBy.username.toUpperCase() }}
            </div>

            <!-- agency -->
            <div class="text-dark font-weight-bold text-body-3 text-nowrap">
              ({{
                isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000
                  ? data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase()
                  : data.item.createdBy.agency.agencyCode.toUpperCase()
              }})
            </div>
            <b-tooltip
              :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
              triggers="hover"
              placement="top"
              boundary="viewport"
              variant="dark"
            >
              <BRow>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Tên tài khoản:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">
                    {{ data.item.createdBy.lastName }}
                    {{ data.item.createdBy.firstName }}
                    ({{ data.item.createdBy.type }})
                  </span>
                </BCol>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Mã tài khoản:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">{{ data.item.createdBy.employeeCode.toUpperCase() }}</span>
                </BCol>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Tên đại lý:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.agencyName }}</span>
                </BCol>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Mã đại lý:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.agencyCode.toUpperCase() }}</span>
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="4"
                  class="text-nowrap"
                >
                  Tên ĐL chủ quản:
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="8"
                >
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.parentAgency.agencyName }}</span>
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="4"
                  class="text-nowrap"
                >
                  Mã ĐL chủ quản:
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="8"
                >
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase() }}</span>
                </BCol>
              </BRow>
            </b-tooltip>
          </div>
        </template>

        <template #cell(flight)="data">
          <div v-if="data.item.bookingRequest">
            <div
              v-for="(trip, index) of resolveBookingRequest(data.item.bookingRequest.itineraries)"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment,indexSegment) in trip.segments"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ segment.devSegmentIndex }}
                  {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}
                  {{ segment.bookingClass }}
                  {{ convertISODateTime(segment.departureTime).dayMonth }}
                  {{ `${segment.departure}${segment.arrival}` }}
                  {{ convertISODateTime(segment.departureTime).hourMin }}
                  {{ convertISODateTime(segment.arrivalTime).hourMin }}
                </span>
              </div>
            </div>
          </div>

          <div v-else>
            <div
              v-for="(trip, index) of data.item.itineraries.filter(trip => trip.some(segment => segment.status !== 'HX'))"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment, indexSegment) in trip"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ convertShortTrip(segment) }}
                </span>
              </div>
            </div>
          </div>
        </template>

        <template #cell(priceNet)="data">
          <div
            class="text-right font-weight-bolder"
            style="color: #00558f"
          >
            {{ formatCurrency((data.item.totalAmountToBePaid + data.item.totalAmountPaid) || 0) }}
          </div>
        </template>

        <template #cell(price)="data">
          <div
            class="text-right font-weight-bolder"
            style="color: #00558f"
          >
            {{ formatCurrency((data.item.totalAmountTicket) || 0) }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          <b-media no-body>
            <b-media-body>
              <h6 class="mb-0">
                {{ convertISODateTime(data.item.dateCreate).time }}
              </h6>
              <h6 class="mb-0">
                {{ convertISODateTime(data.item.dateCreate).date }}
              </h6>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(note)="data">
          <div
            class="two-line-ellipsis"
            style=""
          >
            {{ data.item.note }}
            {{ data.item.autoIssueTime ? `Thời gian xuất vé tự động: ${convertISODateTime(data.item.autoIssueTime).dateTime}`: "" }}
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-start"
          >
            <span class="text-muted">
              {{ $t('paginationText.showing') }}
              <b-dropdown
                v-model="sizePerPage"
                variant="warning"
                :text="`${sizePerPage}`"
                class="per-page-selector d-inline-block p-0"
              >
                <b-dropdown-item
                  v-for="item in sizePerPageLgOptions"
                  :key="item"
                  @click="sizePerPage = item"
                >
                  {{ item }}
                </b-dropdown-item>
              </b-dropdown>
              {{ $t('tickets') }}
              <div>{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }} {{ dataMeta.of }} {{ $t('tickets') }}</div>
            </span>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalReservations"
              :per-page="sizePerPage"
              first-number
              last-number
              class="pagination-warning mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { computed, onUnmounted, ref } from '@vue/composition-api'
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BLink,
  BMedia,
  BMediaBody,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

import {
  resolveBookingStatusVariant,
  resolveGender,
  sizePerPageLgOptions,
  typeOfEmployeeOptions,
  typeOfEmployeeOptionsFilter,
} from '@/constants/selectOptions'
import store from '@/store'

import {
  convertISODateTime,
  convertShortTrip,
  formatCurrency,
  resolveAirlineFlightNumber,
  resolveBorderColorByAirline,
} from '@core/utils/filter'

import reservationStoreModule from '../reservationStoreModule'
import useReservationHandle from '../useReservationHandle'
import ReservationFilter from './ReservationFilter.vue'

export default {
  directives: { 'b-tooltip': VBTooltip },
  components: {
    ReservationFilter,
    BMediaBody,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BOverlay,
    BPagination,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  setup() {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    // Table Handlers
    const tableColumns = [
      { label: 'source', key: 'source' }, // thStyle: 'max-width: 10px'
      { label: 'bookingCode', key: 'bookingCode' },
      { label: 'status', key: 'status' },
      { label: 'passenger', key: 'passenger' },
      { label: 'flight', key: 'flight' },
      { label: 'price', key: 'price' }, // Anh tuấn bảo bỏ đi vì giá ko đúng
      { label: 'priceNet', key: 'priceNet' },
      { label: 'createdAt', key: 'createdAt', sortable: true },
      { label: 'booker', key: 'booker' },
      { label: 'note', key: 'note' },
    ]
    const {
      fetchPastDayBookings,
      sizePerPage,
      currentPage,
      totalReservations,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refReservationListTable,
      refetchData,

      // Extra Filters
      startDateFilter,
      endDateFilter,
      startDateFlightFilter,
      endDateFlightFilter,
      createdByFilter,
      agencyCodeFilter,
      brandFilter,
      sourceFilter,
      statusFilterByUser,
      searchTextFilter,
      bookingCodeFilter,
      isEmptyFilter,
      clearFilter,
      isActiveFilter,
      optionsAirLine,
      optionsSource,
      optionsStatus,

      loading,
      fetchAirportGroup,
    } = useReservationHandle()

    // Register module
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }
    })
    const reservationData = ref({
      note: '',
    })

    if (isEmpty(store.getters['app-reservation/getAirportGroup'])) { fetchAirportGroup() }

    async function editNote(reservation, event) {
      await new Promise(resolve => {
        reservationData.value = reservation
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'reservation-modify-change-note-modal', event)
    }

    const ticketData = ref({})

    function updateSearchText(val) {
      searchTextFilter.value = val
    }

    function updateBookingCode(val) {
      bookingCodeFilter.value = val
    }

    function resolveBookingRequest(trips) {
      let indexSegment = 1
      return trips.map(trip => ({
        ...trip,
        segments: trip.segments.map(segment => {
          const data = {
            ...segment,
            devSegmentIndex: String(indexSegment),
          }

          indexSegment += 1
          return data
        }),
      }))
    }

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

    return {
      fetchPastDayBookings,
      convertISODateTime,
      formatCurrency,
      isEmpty,
      tableColumns,
      sizePerPage,
      currentPage,
      totalReservations,
      dataMeta,
      sizePerPageLgOptions,
      sortBy,
      isSortDirDesc,
      refReservationListTable,
      refetchData,

      // select Options
      typeOfEmployeeOptions,
      typeOfEmployeeOptionsFilter,
      resolveGender,
      optionsStatus,
      optionsAirLine,
      optionsSource,

      // Extra Filters
      startDateFilter,
      endDateFilter,
      startDateFlightFilter,
      endDateFlightFilter,
      createdByFilter,
      agencyCodeFilter,
      brandFilter,
      sourceFilter,
      statusFilterByUser,
      searchTextFilter,
      bookingCodeFilter,
      isEmptyFilter,
      clearFilter,
      reservationData,
      editNote,
      ticketData,

      loading,
      updateSearchText,
      updateBookingCode,
      resolveBookingStatusVariant,
      convertShortTrip,

      resolveBookingRequest,
      isActiveFilter,
      resolveBorderColorByAirline,

      isRoleF1,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  button {
    padding: 4px 16px;
    margin-bottom: 4px;
  }
}
.two-line-ellipsis {
  overflow: hidden;
  max-width: 240px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
